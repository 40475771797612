"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unmatchedPatientsColumnDefs = void 0;
const common_ui_1 = require("@rose/common-ui");
function unmatchedPatientsColumnDefs(save) {
    return [
        {
            headerName: 'ID',
            field: 'extid',
            sort: 'asc',
            width: 250,
        },
        {
            headerName: 'Name',
            valueGetter: params => { var _a, _b; return params.data && `${(_a = params.data.patientData) === null || _a === void 0 ? void 0 : _a.last_name}, ${(_b = params.data.patientData) === null || _b === void 0 ? void 0 : _b.first_name}`; },
        },
        {
            headerName: 'Geburtsdatum',
            field: 'patientData.birthdate',
            valueFormatter: common_ui_1.dateFormatter,
            flex: 1,
        },
        {
            headerName: 'PVS-Patid',
            cellRenderer: 'EditPatidCell',
            cellRendererParams: {
                context: {
                    save,
                },
            },
            width: 300,
        },
    ];
}
exports.unmatchedPatientsColumnDefs = unmatchedPatientsColumnDefs;
