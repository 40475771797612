/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./DoctosyncDoubles.vue?vue&type=template&id=597b6687&scoped=true&lang=pug&"
import script from "./DoctosyncDoubles.vue?vue&type=script&lang=ts&"
export * from "./DoctosyncDoubles.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "597b6687",
  null
  
)

export default component.exports