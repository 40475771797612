"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.object.to-string.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var lodash_1 = require("lodash");

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    doctosyncError: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      showProblems: false
    };
  },
  computed: {
    doubleTerminarten: function doubleTerminarten() {
      var _a;

      return (0, lodash_1.find)((_a = this.doctosyncError) === null || _a === void 0 ? void 0 : _a.doubles, function (_double) {
        return _double.type === 'terminarten';
      });
    },
    doubleBehandler: function doubleBehandler() {
      var _a;

      return (0, lodash_1.find)((_a = this.doctosyncError) === null || _a === void 0 ? void 0 : _a.doubles, function (_double2) {
        return _double2.type === 'behandler';
      });
    }
  },
  methods: {
    toggleShowProblems: function toggleShowProblems() {
      this.showProblems = !this.showProblems;
    }
  }
});