"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsImportColumnsDefs = void 0;
const base_1 = require("../../../../../base");
function settingsImportColumnsDefs() {
    return [
        {
            headerName: 'Start-Zeitpunkt',
            valueFormatter: params => { var _a; return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.importinfo.started) ? (0, base_1.roseDayjs)(params.data.importinfo.started).format('DD.MM.YYYY HH:mm:ss') : ''; },
            resizable: true,
            width: 160,
        },
        {
            headerName: 'End-Zeitpunkt',
            valueFormatter: params => { var _a; return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.importinfo.ended) ? (0, base_1.roseDayjs)(params.data.importinfo.ended).format('DD.MM.YYYY HH:mm:ss') : ''; },
            resizable: true,
            width: 160,
        },
        {
            headerName: 'Dauer',
            field: 'duration',
            valueFormatter: params => {
                var _a;
                return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.duration)
                    ? base_1.roseDayjs
                        .duration(params.data.duration, 'seconds')
                        .format(params.data.duration > 3600 ? 'H[h] m[m] s[s]' : 'm[m] s[s]')
                    : '';
            },
            resizable: true,
            width: 100,
        },
        {
            headerName: 'ID / Aktionen',
            field: 'id',
            cellRenderer: 'ImportIdCell',
            resizable: false,
            width: 250,
        },
        {
            headerName: 'Update',
            field: 'incremental',
            cellRenderer: 'CheckAndCrossCell',
            resizable: true,
            cellStyle: { 'justify-content': 'center' },
            width: 80,
        },
        {
            headerName: 'Patienten',
            field: 'patcount',
            resizable: true,
            width: 100,
        },
        {
            headerName: 'Patienten-IDs & Fehler',
            minWidth: 300,
            flex: 1,
            cellRenderer: 'ImportPatIdsCell',
            resizable: true,
        },
    ];
}
exports.settingsImportColumnsDefs = settingsImportColumnsDefs;
